import React from 'react'
import { PageProps } from 'gatsby'
import { Container, Heading, Stack, Text } from '@chakra-ui/react'
import Layout from '@/layout/base'

const PageNotFound = (_props: PageProps) => (
  <Layout>
    <Container maxW="5xl">
      <Stack
        textAlign="center"
        align="center"
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
          lineHeight="110%"
        >
          Error 404:{' '}
          <Text as="span" color="orange.400">
            página no encontrada
          </Text>
        </Heading>
        <Text color="gray.500" maxW="3xl">
          No hemos encontrado la página que estabas buscando.
        </Text>
      </Stack>
    </Container>
  </Layout>
)

export default PageNotFound
